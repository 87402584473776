// extracted by mini-css-extract-plugin
export var boxHolder = "styles-module--boxHolder--QhR21";
export var breadcrumb = "styles-module--breadcrumb--kDmCK";
export var containerHolder = "styles-module--containerHolder--6jNCw";
export var contentHolder = "styles-module--contentHolder--Z+FYp";
export var contentsHolder = "styles-module--contentsHolder--BW+MQ";
export var header = "styles-module--header--Lk7Uy";
export var holder = "styles-module--holder--JCfOi";
export var howHolder = "styles-module--howHolder--sjPKR";
export var image = "styles-module--image--+87jI";
export var kidsHolder = "styles-module--kidsHolder--wys4M";
export var listenCoughHolder = "styles-module--listenCoughHolder--8s-oN";
export var numberHolder = "styles-module--numberHolder--q3GQX";
export var rightCoughHolder = "styles-module--rightCoughHolder--xWTBw";
export var typesHolder = "styles-module--typesHolder--bz90y";
export var whatHolder = "styles-module--whatHolder--mux8z";
export var whyHolder = "styles-module--whyHolder--qrgC4";