import * as React from "react";
import {Link, PageProps} from "gatsby";
import * as styles from "./styles.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Breadcrumb } from "../../../components/breadcrumb/breadcrumb";
import Layout from "../../../components/layout/layout";
import Container from "../../../components/container/container";
import Heading from "../../../components/basic-components/heading/heading";
import { ProductBar } from "../../../components/product-bar/product-bar";
import { Level } from "react-accessible-headings";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Helmet } from "react-helmet";
import blogImage from "../../../images/Content-image@3x.jpg";
import { useEffect } from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const CoughPage: React.FC<PageProps> = ({ location }: PageProps) => {
  useEffect(() => {
    if (location.hash) {
      scrollTo(location.hash);
    }
  }, []);
  return (
    <Layout
      stickyHeader={false}
      seoConfig={{
        title: "Öksürük Nedir, Nedenleri Nelerdir?",
        description:
          "Öksürük nedir, öksürüğün nedenleri nelerdir? Hayatımızı aniden zorlaştırabilen öksürük hakkında detaylı bilgi almak için sayfamızı ziyaret edin!",
      }}
    >
      <Helmet>
        <script type="application/ld+json">
          {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"},
                  {"@type": "ListItem","position": 3,"name": "Öksürük Nedir, Nedenleri Nelerdir?","item": "https://www.bisolnatur.com.tr/oksuruk/oksuruk-nedir/"}
                ]
            }
          `}
        </script>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "BlogPosting",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${location.href}"
              },
              "headline": "Öksürük Nedir?",
              "image": "${location.origin + blogImage}",  
              "author": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "url": "${location.origin}"
              },  
              "publisher": {
                "@type": "Organization",
                "name": "Bisolnatur",
                "logo": {
                  "@type": "ImageObject",
                  "url": "${location.origin}/logo.svg"
                }
              },
              "datePublished": "2021-09-01",
              "dateModified": "2021-09-01"
            }
          `}
        </script>
        <script type="application/ld+json">{`{"@context":"https://schema.org","@type":"FAQPage","mainEntity":[{"@type":"Question","name":"Öksürük Nedir?","acceptedAnswer":{"@type":"Answer","text":"Hayatımızı aniden zorlaştırabilen öksürük, aslında solunum yollarını temizlemeyi amaçlayan bir reflekstir. Ancak oldukça rahatsız edici olabilir, ayrıca hastalığın çevremizdekilere bulaşmasına da neden olur. Çünkü bir öksürükle çevremize 1,5 litre kadar hava püskürtürüz."}},{"@type":"Question","name":"Öksürük Tipleri Nelerdir?","acceptedAnswer":{"@type":"Answer","text":"<ul><li>Akut Öksürük</li><li>Kronik Öksürük</li><li>Kuru Öksürük</li><li>Balgamlı Öksürük</li></ul>"}},{"@type":"Question","name":"Öksürük Neden Olur?","acceptedAnswer":{"@type":"Answer","text":"Öksürük genellikle virüslerden kaynaklanabilir. Akut ya da kronik öksürük farklı nedenlerle ortaya çıkar. Akut yani 3 haftadan daha kısa süren öksürük genellikle üst yada alt solunum yolu enfeksiyonundan kaynaklanabilir."}},{"@type":"Question","name":"Öksürükten Nasıl Etkileniriz?","acceptedAnswer":{"@type":"Answer","text":"Uzun süren şiddetli öksürük çoğu zaman vücudunuzu bitkin düşürebilir. Böylece günlük rutininizi bozabilir."}}]}`}</script>
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Container fullWidth>
        <div className={classNames(styles.header)}>
          <StaticImage
            className={styles.image}
            src="../../../images/Content-image@3x.jpg"
            alt="Hapşuran çocuk görseli"
            width={1680}
            height={450}
          />
          <Container>
            <Heading>Öksürük Nedir, Nedenleri Nelerdir?</Heading>
            <ul>
              <li>
                <AnchorLink
                  className="blue"
                  to="#oksuruk-nedir"
                  title="Öksürük nedir?"
                >
                  Öksürük nedir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#oksuruk-tipleri-nelerdir"
                  title="Öksürük tipleri nelerdir?"
                >
                  Öksürük tipleri nelerdir?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#oksuruk-neden-olur"
                  title="Öksürük neden olur?"
                >
                  Öksürük neden olur?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#oksurukten-nasil-etkileniriz"
                  title="Öksürükten nasıl etkileniriz?"
                >
                  Öksürükten nasıl etkileniriz?
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className="blue"
                  to="#cocuklarda-oksuruk"
                  title="Çocuklarda öksürük"
                >
                  Çocuklarda öksürük
                </AnchorLink>
              </li>
            </ul>
          </Container>
        </div>
      </Container>
      <Container>
        <div className={styles.holder}>
          <p id="oksuruk-nedir" className="extra-bold">
            Genelde soğuklarla birlikte kendini gösteren öksürük bazen önemli
            bir iş görüşmesinde, bazen çok önemli bir sınavda, bazen en mutlu
            olduğunuz an gibi olmadık yerlerde hayatınızı kâbusa çevirebilir.
            Özellikle uykunun en tatlı, en derin yerinde gelen gece öksürüğü ile
            tüm düzeniniz altüst olur. Hayatımızı kâbusa çeviren öksürüğü geride
            bırakmadan önce birlikte tanıyalım.
          </p>
          <section className={styles.whatHolder}>
            <Level>
              <Heading>Öksürük Nedir?</Heading>
              <p>
                Hayatımızı aniden zorlaştırabilen öksürük, aslında solunum
                yollarını temizlemeyi amaçlayan bir reflekstir. Ancak oldukça
                rahatsız edici olabilir, ayrıca hastalığın çevremizdekilere
                bulaşmasına da neden olur. Çünkü bir öksürükle çevremize 1,5
                litre kadar hava püskürtürüz. Püskürttüğümüz bu havada 3000 adet
                tükürük damlacığı bulunabilir. Bu damlacıklar içlerindeki
                bakteri, virüsler ve diğer yabancı etmenlerle birlikte saatte 80
                km’ye kadar çıkan bir hızla çevremize yayılır. Böylece yayılan
                bakteri, virüsle veya diğer yabancı etmenler hastalığı
                çevremizdekilere taşımaya yardımcı olabilir.
              </p>
              <div className={classNames("flexbox", styles.rightCoughHolder)}>
                <div>
                  <Level>
                    <Heading>Doğru Öksür, Çevreni Koru!</Heading>
                  </Level>
                  <p>
                    Hastalığı çevremizdekilere bulaştırmamak için doğru şekilde
                    öksürmek çok önemlidir:
                  </p>
                  <ul>
                    <li className="blue">
                      Öksürürken ağzınızı ve burnunuzu mendille veya
                      dirseğinizin içiyle kapatın.
                    </li>
                    <li className="blue">
                      Mendil kullandıysanız, öksürdüğünüz mendili çöpe atın ve
                      hemen ellerinizi yıkayın.
                    </li>
                  </ul>
                </div>
                <div>
                  <StaticImage
                    className={styles.image}
                    src="../../../images/img.webp"
                    alt="Hapşuran çocuk görseli"
                    width={280}
                    height={206}
                  />
                </div>
              </div>
              <div className={classNames(styles.listenCoughHolder)}>
                <Level>
                  <Heading>Öksürüğünüzü Dinleyin!</Heading>
                </Level>
                <p>
                  Öksürükler temel olarak 2 sınıfa ayırabiliriz. Bunlardan biri
                  üç haftadan kısa süren ani (akut), diğeri ise 8 haftadan uzun
                  süren müzmin (kronik) öksürüktür. Bu sebeple öksürüğünüzü
                  tanımak için onu dinlemek, süresi ve seyrini bir sağlık meslek
                  mensubu ile takip etmek en iyi yol olabilir. Öksürüğünüzün
                  seyri ile ilgili tüm soruları cevapladığınızda aşağıdaki
                  öksürük tipleriyle karşılaştırabilirsiniz.
                </p>
              </div>
            </Level>
          </section>
          <section id="oksuruk-tipleri-nelerdir" className={styles.typesHolder}>
            <Level>
              <Heading>Öksürük Tipleri Nelerdir?</Heading>
              <div className={classNames(styles.boxHolder)}>
                <Level>
                  <Heading>A. Süresine Göre:</Heading>
                  <div
                    className={classNames("flexbox", styles.containerHolder)}
                  >
                    <div
                      className={classNames("flexbox", styles.contentsHolder)}
                    >
                      <span
                        className={classNames(
                          "bold",
                          "blue",
                          styles.numberHolder
                        )}
                      >
                        1
                      </span>
                      <div className={styles.contentHolder}>
                        <Level>
                          <Heading className="extra-bold">
                            Akut Öksürük:
                          </Heading>
                        </Level>
                        <p>
                          Akut yani üç haftadan kısa süren öksürük genellikle
                          üst ya da alt solunum yolu enfeksiyonlarından
                          kaynaklanır. Akut öksürük grip, soğuk algınlığı,
                          farenjit, bahar alerjisi, bronşit, zatürre, boğmaca
                          hastalığı, tahriş edici madde solumak, akut sinüzit
                          sebepleriyle ortaya çıkabilir. Bu tip bir öksürükle
                          karşı karşıya iseniz, mutlaka bir sağlık kuruluşuna
                          başvurmanız önerilir.
                        </p>
                      </div>
                    </div>
                    <div
                      className={classNames("flexbox", styles.contentsHolder)}
                    >
                      <span
                        className={classNames(
                          "bold",
                          "blue",
                          styles.numberHolder
                        )}
                      >
                        2
                      </span>
                      <div className={styles.contentHolder}>
                        <Level>
                          <Heading className="extra-bold">
                            Kronik Öksürük:
                          </Heading>
                        </Level>
                        <p>
                          Kronik öksürük 8 haftadan uzun süren ve nedeni
                          bilinmeyen öksürük olarak bilinmektedir. Sigara
                          kullanımı, geniz akıntısı, mukoza tabakasında kuruluk,
                          astım, solunum yolu alerjileri, bazı ilaçlar, KOAH,
                          tüberküloz, akciğer kanseri kronik öksürüğe neden
                          olabilecek durumlardan bazılarıdır. Çoğu durumda,
                          öksürükte altta yatan ciddi bir hastalık saptanmaz
                          fakat kronik öksürük gibi uzun süre devam eden
                          öksürüklerde daha dikkatli olunması gerekmektedir.
                          Uzun süren öksürükle karşı karşıya iseniz, mutlaka bir
                          sağlık kuruluşuna başvurmanız önerilir.
                        </p>
                      </div>
                    </div>
                  </div>
                </Level>
              </div>
              <div className={classNames(styles.boxHolder)}>
                <Level>
                  <Heading>B. Tipine Göre:</Heading>
                  <div
                    className={classNames("flexbox", styles.containerHolder)}
                  >
                    <div
                      className={classNames("flexbox", styles.contentsHolder)}
                    >
                      <span
                        className={classNames(
                          "bold",
                          "blue",
                          styles.numberHolder
                        )}
                      >
                        1
                      </span>
                      <div className={styles.contentHolder}>
                        <Level>
                          <Heading className="extra-bold">
                            Kuru Öksürük:
                          </Heading>
                        </Level>
                        <p>
                          <Link
                            className="blue underline"
                            to={"/oksuruk/kuru-oksuruk-nedir/"}
                          >
                            Kuru öksürük
                          </Link>
                          , ciddi artış gösterdiği zaman özellikle gece
                          yaşanıyorsa hayat kalitesini olumsuz etkileyebilir.
                          Genellikle balgam üretimi olmayan öksürük olarak
                          tanımlanan bu vaka, astım gibi durumlarda nadiren
                          balgam üretimiyle de kendini gösterebilir. Kuru
                          öksürükle birlikte göğüste ağrı, yanma ve yutma
                          güçlüğü gibi belirtileri de vardır. Kuru öksürüğe yol
                          açan yaygın nedenler astım, viral üst solunum yolu
                          enfeksiyonları, çevresel tahriş edici maddeler, geniz
                          akıntısı ve boğmaca hastalığı olabilir. Bu tip bir
                          öksürükle karşı karşıya iseniz, mutlaka bir sağlık
                          kuruluşuna başvurmanız önerilir.
                        </p>
                      </div>
                    </div>
                    <div
                      className={classNames("flexbox", styles.contentsHolder)}
                    >
                      <span
                        className={classNames(
                          "bold",
                          "blue",
                          styles.numberHolder
                        )}
                      >
                        2
                      </span>
                      <div className={styles.contentHolder}>
                        <Level>
                          <Heading className="extra-bold">
                            Balgamlı Öksürük:
                          </Heading>
                        </Level>
                        <p>
                          Soğuk algınlığı ve gribin neden olabileceği,
                          {" "}
                          <Link
                            className="blue underline"
                            to={"/oksuruk/balgamli-oksuruk/"}
                          >
                            balgamlı öksürük
                          </Link>
                          , balgam üretimi görülen öksürük olarak tanımlanır.
                          Boğazda bir şey sıkışmış gibi bir hisse neden olur.
                          Balgamlı öksürüğe neden olan diğer etmenler bronşit,
                          zatürre, kronik obstrüktif akciğer hastalıkları
                          olabilir. But tip bir öksürükle karşı karşıya iseniz,
                          mutlaka bir sağlık kuruluşuna başvurmanız önerilir.
                        </p>
                      </div>
                    </div>
                  </div>
                </Level>
              </div>
            </Level>
          </section>
          <section id="oksuruk-neden-olur" className={styles.whyHolder}>
            <Level>
              <Heading>Öksürük Neden Olur?</Heading>
              <p>
                Öksürük genellikle virüslerden kaynaklanabilir. Akut ya da
                kronik öksürük farklı nedenlerle ortaya çıkar. Akut yani 3
                haftadan daha kısa süren öksürük genellikle üst yada alt solunum
                yolu enfeksiyonundan kaynaklanabilir.
                <br />
                <br />
                Üst ve alt solunum yollarını etkileyerek öksürüğe neden olabilen
                durumları bazılar aşağıda sıralanmıştır;
              </p>
              <Level>
                <Heading>Akut Öksürüğe neden olabilen durumlar</Heading>
                <ul>
                  <li className="blue">Grip</li>
                  <li className="blue">Akut Sinüzit</li>
                  <li className="blue">Soğuk Algınlığı</li>
                  <li className="blue">Farenjit</li>
                  <li className="blue">Bahar Alerjisi</li>
                  <li className="blue">Bronşit</li>
                  <li className="blue">Zatürre</li>
                  <li className="blue">Boğmaca</li>
                  <li className="blue">Tahriş edici madde solumak</li>
                </ul>
              </Level>
              <Level>
                <Heading>
                  Kronik öksürüğe neden olabilen durumlardan bazıları şunları
                  içerir;
                </Heading>
                <ul>
                  <li className="blue">Sigara kullanımı</li>
                  <li className="blue">Geniz akıntısı</li>
                  <li className="blue">
                    Solunum yollarını döşeyen mukoza tabakasında kuruluk
                  </li>
                  <li className="blue">Gastroözofageal reflü hastalığı</li>
                  <li className="blue">Astım</li>
                  <li className="blue">Solunum yolu alerjileri</li>
                  <li className="blue">
                    Bazı ilaçlar (Örneğin ACE inhibitörleri)
                  </li>
                  <li className="blue">Kistik Fibrozis</li>
                  <li className="blue">KOAH</li>
                  <li className="blue">
                    Üst solunum yolu ve yutma kaslarının kaslarının
                    koordinasyonunu zayıflatan parkinsonizim gibi nörümüsküler
                    hastalıklar
                  </li>
                  <li className="blue">Tüberküloz</li>
                  <li className="blue">Akciğer mantar enfeksiyonları</li>
                  <li className="blue">Akciğer embolisi</li>
                  <li className="blue">Akciğer kanseri</li>
                </ul>
              </Level>
            </Level>
          </section>
          <section
            id="oksurukten-nasil-etkileniriz"
            className={styles.howHolder}
          >
            <Level>
              <Heading>Öksürükten Nasıl Etkileniriz?</Heading>
            </Level>
            <p>
              Uzun süren şiddetli öksürük çoğu zaman vücudunuzu bitkin
              düşürebilir. Böylece günlük rutininizi bozabilir.
              <br />
              <br />
              Özellikle gece gelen öksürüklerle bozulan uyku düzeniniz günlük
              hayatınızı sekteye uğratabilir. Bu yüzden okul veya işinizde
              sürekli bir yorgunluk, uykusuzluk ve verimsizlik
              hissedebilirsiniz. Ailenizle veya arkadaşlarınızla birlikteyken
              gelen ani öksürükler yüzünden bazen konuşmakta bile güçlük
              çekebilirsiniz.
              <br />
              <br />
              Tüm bu durumların önüne geçmek için öksürük iyice şiddetlenmeden
              önce önlem almanız, öksürüğünüzü takip etmeniz sağlığınız için iyi
              olacaktır. Bu tip bir öksürükle karşı karşıya iseniz, mutlaka bir
              sağlık kuruluşuna başvurmanız önerilir.
            </p>
          </section>
          <section id="cocuklarda-oksuruk" className={styles.kidsHolder}>
            <Level>
              <Heading>Çocuklarda Öksürük</Heading>
              <p>
                Pek çok etken yetişkinlerdeki gibi,{" "}
                <Link
                  className="blue underline"
                  to={"/oksuruk/cocuklarda-oksuruge-ne-iyi-gelir/"}
                >
                  çocuklarda da öksürüğe
                </Link>{" "}
                neden olabilir. Bu sebeple beslenme düzeni de çocukları
                öksürükten korumada önemli bir etmendir. Yeterli ve kaliteli
                çocukların bağışıklık sistemi güçlenir, bakteri ve virüslere
                karşı dirençli hale gelerek öksürükten kısa sürede
                kurtulabilirler.
              </p>
              <Level>
                <Heading>
                  Çocuklarda Öksürüğe İyi Gelen Destekleyici İçerik: Bal
                </Heading>
                <p>
                  İlk destekleyici içerik olarak akıllara bal gelmektedir. Bir
                  yaşın üzerindeki çocuklara bal verilerek özellikle gece
                  öksürüklerinin etkisi azaltılması amaçlanır. Bal, nadiren de
                  olsa alerjik reaksiyona neden olabilir, bu nedenle çocuklarda
                  kullanmadan önce hekiminize danışmanız önerilir.
                </p>
                <Heading>Öksürük Tedavisinde Kekiğin Önemi</Heading>
                <p>
                  Bal dışında öküsürük tedavisi için kullanabileceğiniz bir
                  diğer destekleyici ise kekiktir. Kekik yapraklarındaki
                  flavonoid adı verilen bileşik, antioksidan etki gösterir.
                </p>
                <Heading>Öksürüğün Etkili Çözümü</Heading>
                <p>
                  Kendinizi ve sevdiklerinizi öksürükten korumak için doğru
                  adımları attığınızdan emin misiniz? Bazen ne yaparsanız yapın,
                  zamansız gelen öksürük bir türlü peşinizi bırakmaz. Bu
                  yetmiyormuş gibi bir de uykusuzluk, yorgunluk ve iş yerinde
                  verimsizlik gibi can sıkıcı durumlar öksürükle birlikte
                  hayatınıza girer. “Bu öksürük nasıl geçer?” sorusu kafanızın
                  içinde dönüp durur ama endişelenmeyin doğru adımlarla öksürüğü
                  yenmek mümkün.
                  <br />
                  <br />
                  Böyle durumlarda bolca su tüketmek, düzenli uyku ve sporla
                  vücudu dinç tutmak, havasız ve tozlu ortamlardan uzak durmak,
                  doğal gıdalarla düzenli beslenerek bağışıklık sistemini
                  desteklemek öksürüğe karşı ev ortamında alabileceğiniz basit
                  önlemlerden bazılarıdır.
                  <br />
                  <br />
                  Öksürükten korunmanın bir başka önemli yolu da hijyendir.
                  Bakteri ve virüslerden korunmak için gün içerisinde sık sık
                  eller yıkanmalı ve vücut temizliğine çok dikkat edilmelidir.
                  Özellikle toplu taşımalara, kabalık ortamlarda ekstra dikkat
                  edilmeli ve mutlaka bu ortamlardan çıktıktan sonra eller
                  yıkanmalıdır.
                  <br />
                  <br />
                  Ne kadar önlem alıp hijyen kurallarına dikkat etseniz de bazen
                  öksürük sizi aniden yakalayabilir.. İşte böyle durumlarda, her
                  an elinizin altında bulunması gereken doğal içeriğe sahip
                  bir üründen yardım almanız gerekebilir.
                  <br />
                  <br />
                  Bisolnatur özellikle üst solunum yolları ile ilişkili
                  öksürüklerin (kuru ve balgamlı öksürük) giderilmesinde
                  kullanılan bir tıbbi cihazdır.
                  <br />
                  <br />
                  Bisolnatur tahriş olan mukoza üzerinde iki farklı şekilde
                  mekanik etki sergiler.
                </p>
                <ul>
                  <li className="blue">
                    Mukusun nemlenmesini destekleyerek fizyolojik olarak
                    atilmasını kolaylaştırır.
                  </li>
                  <li className="blue">
                    Mukoza üzerinde bir “film” oluşturarak tahriş eden dış
                    etkenler ile temasını engeller.
                  </li>
                </ul>
              </Level>
            </Level>
          </section>
        </div>
        <div className="date">
          <p>Yayınlama Tarihi: 2021-09-01</p>
          <p>Son güncellenme Tarihi: 2021-09-01</p>
        </div>
      </Container>
      <ProductBar />
    </Layout>
  );
};
export default CoughPage;
